import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { FC } from "react";

import type { AviaryColors } from "@aviary/types/aviaryColors";
import { Size } from "@shared/types/sizes";

import type { IconButtonProps } from "../IconButton";
import { IconButton } from "../IconButton";

import * as styles from "./CloseButton.styles";

const defaultColor: AviaryColors = "dark";

interface Props extends Omit<IconButtonProps, "type"> {
  /**
   * If the placement of the close button should be absolutely positioned
   *
   */
  absolutePosition?: "topRight";
  /**
   * Specific variant styles/position used in modals
   */
  isModal?: boolean;
  isLight?: boolean;
}

const CloseButton: FC<Props> = ({
  isText,
  isSize,
  isModal,
  absolutePosition,
  isLight,
  ...rest
}) => {
  const iconSizer = () => {
    if (!isSize) return "1x";
    switch (isSize) {
      case Size.XSMALL:
        return "lg";
      case Size.SMALL:
        return "2x";
      case Size.LARGE:
        return "3x";
      default:
        break;
    }
  };

  const closeStyles = [
    absolutePosition && styles.position[absolutePosition],
    isModal && styles.modalVersion,
    isLight && styles.lightHover,
  ];
  return (
    <IconButton css={closeStyles} isCircular isSize={isSize} {...rest} isText={isText}>
      <FontAwesomeIcon icon={faTimes} size={iconSizer()} />
    </IconButton>
  );
};

CloseButton.defaultProps = {
  isColor: defaultColor,
  isSize: Size.XSMALL,
  isText: true,
};

export { CloseButton };
