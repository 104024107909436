import { css } from "@emotion/react";

import { colors, typography, dimensions } from "@styles";

export const base = css`
  color: ${colors.dark};
  font-weight: ${typography.strong};
  line-height: 1.125;
`;

export const titleBase = css`
  ${base};
  font-size: 2rem;
  padding: 1rem 0;

  strong {
    color: ${colors.primary};
    font-weight: ${typography.semiBold};
  }
`;

export const paragraphBase = css`
  ${base};
  font-weight: ${typography.regular};
  font-size: 1rem;
`;

// Modifiers
export const margin = css`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;
export const indented = css`
  margin-left: 1rem;
`;
export const fullwidth = css`
  width: 100%;
`;

// Font colors
export const isMuted = css`
  color: ${colors.grey.darkFamily.light};
`;

// Font weights
const getBoldWeight = isSecondaryWeight => {
  if (isSecondaryWeight) {
    return css`
      font-weight: ${typography.semiBold};
    `;
  }
  return css`
    font-weight: ${typography.bold};
  `;
};
const getSemiBoldWeight = isSecondaryWeight => {
  if (isSecondaryWeight) {
    return css`
      font-weight: ${typography.regular};
    `;
  }
  return css`
    font-weight: ${typography.semiBold};
  `;
};

// Sizes
export const titleSizes = {
  h1: isSecondaryWeight => css`
    font-size: ${typography.h1};
    line-height: 2.5rem;
    ${getBoldWeight(isSecondaryWeight)}

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${typography.h1Mobile};
    }
  `,
  h2: isSecondaryWeight => css`
    font-size: ${typography.h2};
    line-height: 2.5rem;
    ${getSemiBoldWeight(isSecondaryWeight)}

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${typography.h2Mobile};
      line-height: 2rem;
    }
  `,
  h3: isSecondaryWeight => css`
    font-size: ${typography.h3};
    line-height: 2rem;
    ${getSemiBoldWeight(isSecondaryWeight)}

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${typography.h3Mobile};
    }
  `,
  h4: isSecondaryWeight => css`
    font-size: ${typography.h4};
    line-height: 1.5rem;
    ${getSemiBoldWeight(isSecondaryWeight)}

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${typography.h4Mobile};
    }
  `,
  h5: isSecondaryWeight => css`
    font-size: ${typography.h5};
    line-height: 1.5rem;
    ${getSemiBoldWeight(isSecondaryWeight)}

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${typography.h5Mobile};
    }
  `,
};
export const paragraphSizes = {
  body: isSecondaryWeight => css`
    font-size: ${typography.body};
    line-height: 1.5rem;
    ${getSemiBoldWeight(!isSecondaryWeight)}
  `,
  footnote: isSecondaryWeight => css`
    font-size: ${typography.footnote};
    line-height: 1rem;
    ${getSemiBoldWeight(!isSecondaryWeight)}
  `,
  caption: isSecondaryWeight => css`
    font-size: ${typography.caption};
    line-height: 1rem;
    ${getSemiBoldWeight(!isSecondaryWeight)}
  `,
};
export const sizeOverride = {
  h1: isSecondaryWeight => css`
    font-size: ${typography.h1};
    line-height: 2.5rem;
    ${getBoldWeight(isSecondaryWeight)}

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${typography.h1Mobile};
    }
  `,
  h2: isSecondaryWeight => css`
    font-size: ${typography.h2};
    line-height: 2.5rem;
    ${getSemiBoldWeight(isSecondaryWeight)}

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${typography.h2Mobile};
      line-height: 2rem;
    }
  `,
  h3: isSecondaryWeight => css`
    font-size: ${typography.h3};
    line-height: 2rem;
    ${getSemiBoldWeight(isSecondaryWeight)}

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${typography.h3Mobile};
    }
  `,
  h4: isSecondaryWeight => css`
    font-size: ${typography.h4};
    line-height: 1.5rem;
    ${getSemiBoldWeight(isSecondaryWeight)}

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${typography.h4Mobile};
    }
  `,
  h5: isSecondaryWeight => css`
    font-size: ${typography.h5};
    line-height: 1.5rem;
    ${getSemiBoldWeight(isSecondaryWeight)}

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${typography.h5Mobile};
    }
  `,
  body: isSecondaryWeight => css`
    font-size: ${typography.body};
    line-height: 1.5rem;
    ${getSemiBoldWeight(!isSecondaryWeight)}

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${typography.body};
    }
  `,
  footnote: isSecondaryWeight => css`
    font-size: ${typography.footnote};
    line-height: 1rem;
    ${getSemiBoldWeight(!isSecondaryWeight)}

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${typography.footnote};
    }
  `,
  caption: isSecondaryWeight => css`
    font-size: ${typography.caption};
    line-height: 1rem;
    ${getSemiBoldWeight(!isSecondaryWeight)}

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${typography.caption};
    }
  `,
};
