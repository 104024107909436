import { css } from "@emotion/react";

import { colors, animations, layers, timing } from "@styles";

export const position = {
  topRight: css`
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
  `,
};

export const modalVersion = css`
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  z-index: ${layers.indexModalMax};

  path {
    ${animations.transition(undefined, timing.slow)};
    fill: ${colors.grey.lightFamily.dark};
  }

  &:hover path {
    fill: ${colors.grey.base};
  }
`;

export const storyRow = css`
  display: flex;
  align-items: center;
  justify-content: center;

  & > button {
    margin-right: 1rem;
  }
`;

export const storyLayout = css`
  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 1rem;
  }
`;

export const lightHover = css`
  &:focus,
  &:hover,
  &:active {
    background-color: ${colors.white};
    svg path {
      fill: ${colors.info};
    }
  }
`;
