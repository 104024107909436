import { css } from "@emotion/react";

import { hexToRgba } from "@styles/emotion-styles/helpers";

import * as colors from "./colors";
import { modalBorderRadius } from "./dimensions";

export const controlBase = css`
  appearance: none;
  box-shadow: none;

  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  line-height: 1.4;
  vertical-align: top;

  color: ${colors.dark};
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 1rem;

  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
  }
`;

export const cardShadow = css`
  box-shadow: 0px 1px 2px ${hexToRgba(colors.grey.base, 0.25)};
`;

const modalValues = {
  offsetX: 0,
  offsetY: "2px",
  blurRadius: "4px",
  spreadRadius: 0,
  color: hexToRgba(colors.dark, 0.2),
};

export const modalStyles = css`
  border-radius: ${modalBorderRadius};
  box-shadow: ${modalValues.offsetX} ${modalValues.offsetY} ${modalValues.blurRadius}
    ${modalValues.spreadRadius} ${modalValues.color};
`;

export const modalHeader = css`
  box-shadow: ${modalValues.offsetX} ${modalValues.offsetY} ${modalValues.blurRadius}
    ${modalValues.spreadRadius} ${modalValues.color};
`;

export const modalFooter = css`
  box-shadow: ${modalValues.offsetX} ${`-${modalValues.offsetY}`} ${modalValues.blurRadius}
    ${modalValues.spreadRadius} ${modalValues.color};
`;

export const footerShadow = css`
  box-shadow: 0px -3px 8px ${hexToRgba(colors.grey.base, 0.25)};
`;

export const popoverShadow = css`
  box-shadow: 0px 3px 8px ${hexToRgba(colors.grey.base, 0.25)};
`;
