import { FC } from "react";
import { useTranslation } from "react-i18next";

import { CloseButton } from "@aviary";

import { ToastType } from "./toastTypes";

import * as styles from "./Toast.styles";

interface Props {
  /**
   * Sets the color of the alert
   *
   * @default normal
   */
  type: ToastType;
  /**
   * Determines if the Toast wil auto-dismiss after the timeout period
   *
   * @default true
   */
  autoDismiss: boolean;
  /**
   * The time the toast stays on screen if autoDismiss is true
   *
   * @default 5000
   */
  autoDismissTimeout?: number;
  /**
   * Determines if the close button is visible, used in the situation if you require the users attention
   *
   * @default true
   */
  isCloseable?: boolean;
  /**
   * Callback for the close button
   *
   */
  onDismiss?: () => void;
  /**
   * Callback mouseEnter, used to pause the autoDismissal timer
   *
   */
  onMouseEnter?: () => void;
  /**
   * Callback mouseEnter, used to restart the autoDismissal timer
   *
   */
  onMouseLeave?: () => void;
  /**
   * Remaining time left in the timer
   *
   */
  timeRemaining?: number;
}

const Toast: FC<Props> = props => {
  const {
    type,
    autoDismiss,
    autoDismissTimeout,
    timeRemaining,
    children,
    isCloseable,
    onDismiss,
    ...rest
  } = props;
  const { t } = useTranslation("aviary");

  const styleBuilder = [styles.content, autoDismiss && styles.content, styles.types[type]];
  const timerBuilder = [styles.timer, styles.timerTypes[type]];
  const progressBuilder = [
    styles.timeDown(autoDismissTimeout, timeRemaining),
    styles.progressTypes[type],
  ];

  return (
    <div css={styles.base} {...rest} aria-live="polite">
      <div css={timerBuilder}>
        <div css={progressBuilder}>&nbsp;</div>
      </div>
      <div css={styleBuilder}>
        {children}
        {isCloseable && (
          <CloseButton
            css={styles.close}
            onClick={onDismiss}
            absolutePosition="topRight"
            aria-label={t("CloseNotification")}
            isColor="system"
          />
        )}
      </div>
    </div>
  );
};

Toast.defaultProps = {
  isCloseable: true,
};

export type { Props as ToastProps };
export { Toast };
