import { css } from "@emotion/react";

export const heading = css`
  margin-top: 2.125rem;
`;

export const subHeading = css`
  margin-top: 1.75rem;
`;

export const td = css`
  &:not(:last-child) {
    padding-right: 1rem;
  }
`;
