import * as colors from "@styles/emotion-styles/colors";

import type { ColorProfile } from "./colorProfile";

export const purpleProfile: ColorProfile = {
  baseColor: colors.purple.darkFamily.normal,
  baseText: colors.white,

  hoverColor: colors.purple.darkFamily.light,
  hoverText: colors.white,

  activeColor: colors.purple.darkFamily.darker,
  outlineActiveColor: colors.purple.lightFamily.normal,

  lightNormalColor: colors.purple.lightFamily.normal,
  extraLightColor: colors.purple.lightFamily.light,
};
