import { FC } from "react";

import * as styles from "./UnorderedList.styles";
interface Props {
  className: string;
}

const UnorderedList: FC<Props> = ({ className, ...props }) => {
  const isChecklist = className === "contains-task-list";

  return <ul {...props} css={!isChecklist && styles.list} />;
};

export { UnorderedList };
