const monthSelector = {
  January: "January",
  February: "February",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December",
  SelectDate: "select date, the current date selected is {{month}} {{year}}",
  SelectDateYear: "select date, the current date selected is {{year}}",
  SelectAllDates: "select date",
  previousYear: "previous year",
  nextYear: "next year",
  previousMonth: "previous month",
  nextMonth: "next month",
  AllDates: "All dates",
  Clear: "Clear",
} as const;

const attachment = {
  removeAttachment: "Remove attachment: {{name}}",
  Remove: "Remove",
  addAttachment: "Add",
  attachmentName: "Attachment: {{name}}",
  showMore: "Show more",
  showLess: "Show less",
} as const;

const searchBar = {
  ClearSearch: "Clear search",
  Search: "Search",
} as const;

const dropdown = {
  GoBack: "Go back",
} as const;

const a11yMenu = {
  AccessibleNavigation: "Accessible navigation",
  SkipToMainContent: "Skip to main content",
} as const;

const breadcrumb = {
  collapsedBreadcrumb: "Collapsed breadcrumb",
} as const;

const button = {
  Loading: "Loading",
};

const toast = {
  CloseNotification: "Close notification",
} as const;

const modal = {
  CloseModal: "Close modal",
} as const;

const aviary = {
  dropdown,
  monthSelector,
  searchBar,
  attachment,
  a11yMenu,
  breadcrumb,
  button,
  toast,
  modal,
};

export { aviary };
