import { createTheme } from "@mui/material";

import { colors, typography, layers } from "@styles";

const baseThemeOptions = {
  palette: {
    primary: {
      main: colors.primary,
    },
    error: {
      main: colors.danger,
    },
    text: {
      primary: colors.dark,
    },
  },
  typography: {
    fontFamily: typography.familySansSerif,
  },
  zIndex: {
    modal: layers.indexModal,
  },
};

const baseTheme = createTheme(baseThemeOptions);

export { baseTheme, baseThemeOptions };
