import { Global } from "@emotion/react";
import { Tooltip as MUITooltip, ThemeProvider } from "@mui/material";
import type { Theme } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { FC, ReactNode } from "react";

import { baseTheme } from "@aviary/theme";
import { PopperPositionType } from "@aviary/types/popperPositions";
import { colors, helpers, layers } from "@styles";

import * as styles from "./Tooltip.styles";

interface Props {
  tooltipContent: ReactNode;
  textDirection?: "left" | "right" | "center";
  placement?: PopperPositionType;
  interactive?: boolean;
}
const Tooltip: FC<Props> = ({
  textDirection,
  tooltipContent,
  placement,
  interactive,
  children,
  ...rest
}) => {
  const tooltipStyles = [styles.wrapper, textDirection && styles.textDirection[textDirection]];

  const renderTooltipContent = () => {
    return <>{tooltipContent}</>;
  };

  const AviaryTooltip = withStyles((theme: Theme) => ({
    popper: {
      zIndex: layers.zIndexTopForeground,
    },
    arrow: {
      color: colors.dark,
    },
    tooltip: {
      backgroundColor: colors.dark,
      color: colors.white,
      fontSize: theme.typography.pxToRem(14),
      padding: "0.5rem 0.75rem",
      boxShadow: `${helpers.hexToRgba(colors.dark, 0.25)} 0 3px 8px 0`,
    },
  }))(MUITooltip);

  return (
    <>
      <Global styles={styles.tooltipOverrides} />
      <ThemeProvider theme={baseTheme}>
        <AviaryTooltip
          title={renderTooltipContent()}
          arrow={true}
          placement={placement}
          disableTouchListener={true}
          disableInteractive={!interactive}
          leaveDelay={200}
        >
          <div css={tooltipStyles} tabIndex={0} {...rest}>
            {children}
          </div>
        </AviaryTooltip>
      </ThemeProvider>
    </>
  );
};

Tooltip.defaultProps = { placement: "top", textDirection: "right" };

export { Tooltip };
