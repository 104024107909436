import * as colors from "@styles/emotion-styles/colors";

import type { ColorProfile } from "./colorProfile";

export const warningProfile: ColorProfile = {
  baseColor: colors.orange.darkFamily.normal,
  baseText: colors.white,

  hoverColor: colors.orange.darkFamily.light,
  hoverText: colors.white,

  activeColor: colors.orange.darkFamily.darker,
  outlineActiveColor: colors.orange.darkFamily.dark,

  lightNormalColor: colors.orange.lightFamily.normal,
  extraLightColor: colors.orange.lightFamily.light,
};
