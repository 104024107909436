import { css } from "@emotion/react";

import { animations, colors, dimensions, typography } from "@styles";

export const baseSkeleton = css`
  ${animations.skeletonPulse};
  background-color: ${colors.grey.base};
`;

export const button = {
  base: css`
    ${baseSkeleton};
    border-radius: ${dimensions.borderRadius};
  `,
  xsmall: css`
    width: 4rem;
    height: 1.5rem;
  `,
  small: css`
    width: 4rem;
    height: 2.25rem;
  `,
  medium: css`
    width: 6rem;
    height: 2.75rem;
  `,
  large: css`
    width: 8rem;
    height: 3.5rem;
  `,
};

export const floated = {
  left: css`
    float: left;
  `,
  right: css`
    float: right;
  `,
};

export const heading = {
  base: css`
    ${baseSkeleton};
    min-width: 5rem;
    width: 30%;
    border-radius: 3px;
  `,
  h1: css`
    height: ${typography.h1};
  `,
  h2: css`
    height: ${typography.h2};
  `,
  h3: css`
    height: ${typography.h3};
  `,
  h4: css`
    height: ${typography.h4};
  `,
  h5: css`
    height: ${typography.h4};
  `,
  body: css`
    height: ${typography.body};
  `,
  footnote: css`
    height: ${typography.footnote};
  `,
  caption: css`
    height: ${typography.caption};
  `,
};

export const image = {
  base: css`
    ${baseSkeleton};
    border-radius: 3px;
  `,
  tiny: css`
    height: 1rem;
    width: 1rem;
  `,
  small: css`
    height: 2rem;
    width: 2rem;
  `,
  medium: css`
    height: 2.5rem;
    width: 2.5rem;
  `,
  large: css`
    height: 4rem;
    width: 4rem;
  `,
  extraLarge: css`
    height: 12rem;
    width: 12rem;
  `,
  fill: css`
    height: 100%;
    width: 100%;
  `,
};

export const fullWidth = css`
  width: 100%;
`;

export const circular = css`
  border-radius: 100%;
`;

export const customWidth = (width: number) => css`
  width: ${width}rem;
`;

export const customHeight = (height: number) => css`
  height: ${height}rem;
`;

export const margin = {
  bottom: css`
    margin-bottom: 0.5rem;
  `,
  bottomLarge: css`
    margin-bottom: 1rem;
  `,
  bottomButton: css`
    margin-bottom: 1.5rem;
  `,
  top: css`
    margin-top: 0.5rem;
  `,
  topLarge: css`
    margin-top: 1rem;
  `,
  topButton: css`
    margin-top: 1.5rem;
  `,
  topAndBottom: css`
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  `,
  topAndBottomLarge: css`
    margin-bottom: 1rem;
    margin-top: 1rem;
  `,
};
