import { TFunctionResult } from "i18next";
import { createContext, ReactChild } from "react";

import { ToastType } from "./toastTypes";

interface ToastOptions {
  autoDismiss?: boolean;
  autoDismissTimeout?: number;
  toastId?: string;
  isCloseable?: boolean;
  onClose?: () => void;
}

interface ToastData {
  makeToast: (
    status: ToastType,
    content: ReactChild | TFunctionResult,
    options?: ToastOptions
  ) => void;
  removeToast?: (toastId: string) => void;
}

const ToastContext = createContext<ToastData>(null);

export type { ToastData, ToastOptions };
export { ToastContext };
