import { css } from "@emotion/react";

import { colors } from "@styles";

export const inlineCode = css`
  background-color: ${colors.grey.lightFamily.normal};
  color: ${colors.grey.darkFamily.normal};
  border-radius: 0.25rem;
  padding: 0.125rem 0.5rem;
  display: inline-flex;
  word-break: break-word;
`;
