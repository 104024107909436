import { faCopy } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, ReactNode, useState } from "react";
import ReactClipboard from "react-clipboard.js";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import bash from "react-syntax-highlighter/dist/cjs/languages/hljs/bash";
import htmlbars from "react-syntax-highlighter/dist/cjs/languages/hljs/htmlbars";
import json from "react-syntax-highlighter/dist/cjs/languages/hljs/json";
import typescript from "react-syntax-highlighter/dist/cjs/languages/hljs/typescript";
import defaultStyle from "react-syntax-highlighter/dist/cjs/styles/hljs/default-style";

SyntaxHighlighter.registerLanguage("json", json);
SyntaxHighlighter.registerLanguage("typescript", typescript);
SyntaxHighlighter.registerLanguage("htmlbars", htmlbars);
SyntaxHighlighter.registerLanguage("bash", bash);

import { IconButton, Tooltip } from "@aviary";
import { colors } from "@styles";

import * as styles from "./CustomCode.styles";

interface Props {
  children: ReactNode;
  className?: string;
}

const LANGUAGE = {
  "language-html": "htmlbars",
  "language-js": "javascript",
  "language-json": "json",
  "language-ts": "typescript",
  "language-shell": "bash",
  "language-sh": "bash",
};

const CustomCode: FC<Props> = ({ children, className }) => {
  const [toolTipContent, setTooltipContent] = useState<string>("Copy");

  const customStyle = {
    background: colors.grey.lightFamily.normal,
    width: "100%",
    color: colors.blue.darkFamily.dark,
    padding: "2rem 1rem 0.5rem",
    borderRadius: "0.5rem",
  };

  return (
    <div css={styles.container}>
      <SyntaxHighlighter
        language={LANGUAGE[className] || "text"}
        style={defaultStyle}
        customStyle={customStyle}
      >
        {children}
      </SyntaxHighlighter>

      <ReactClipboard
        data-clipboard-text={children}
        component="div"
        css={styles.clipBoard}
        onSuccess={() => setTooltipContent("Copied")}
      >
        <Tooltip tooltipContent={toolTipContent}>
          <IconButton
            noStyle
            isSize="xsmall"
            aria-label="Copy code snippet"
            onMouseLeave={() => setTooltipContent("Copy")}
            css={styles.iconButton}
          >
            <FontAwesomeIcon icon={faCopy} css={styles.copyIcon} />
          </IconButton>
        </Tooltip>
      </ReactClipboard>
    </div>
  );
};

export { CustomCode };
