import { FC, useState, ReactChild } from "react";
import { useTransition, animated } from "react-spring";

import { ToastContext, ToastOptions } from "../ToastContext";
import { ToastController } from "../ToastController";
import { ToastType } from "../toastTypes";

import * as styles from "./Toaster.styles";

let id = 0;

const MAX_NUMBER_TOASTS = 3;

const Toaster: FC = ({ children }) => {
  const [refMap] = useState(() => new WeakMap());
  const [cancelMap] = useState(() => new WeakMap());
  const [items, setItems] = useState<any>([]);

  const transitions = useTransition(items, (item): any => item.key, {
    from: { opacity: 0, height: 0 },
    enter: item => async next =>
      await next({ opacity: 1, height: refMap.get(item).offsetHeight + 8 }),
    leave: item => async (next, cancel) => {
      cancelMap.set(item, cancel);
      await next({ opacity: 0 });
      await next({ height: 0 });
    },
    // Typed as 'any' due to TS inferring promises as 'any' (https://github.com/microsoft/TypeScript/issues/33752)
  } as any);

  const makeToast = (type: ToastType, content: ReactChild, options?: ToastOptions) => {
    const uniqID = options?.toastId ? options.toastId : id++;
    if (transitions.length < MAX_NUMBER_TOASTS) {
      return setItems(state => [...state, { key: uniqID, type, content, options }]);
    }
  };

  const doCallBack = key => {
    items.filter(item => item.key === key).forEach(item => item.options?.onClose?.());
  };

  const timeoutToast = key => () => {
    doCallBack(key);
    setItems(state => state.filter(i => i.key !== key));
  };

  const removeToast = key => {
    doCallBack(key);
    setItems(state => state.filter(i => i.key !== key));
  };

  const renderToasts = () => (
    <div css={styles.base}>
      {transitions.map(({ key, item, props: { ...style } }) => (
        <animated.div key={key} style={style}>
          <div ref={ref => ref && refMap.set(item, ref)}>
            <ToastController
              type={item.type ? item.type : null}
              autoDismiss={item.options?.autoDismiss}
              autoDismissTimeout={item.options?.autoDismissTimeout}
              onDismiss={timeoutToast(item.key)}
              isCloseable={item.options?.isCloseable}
            >
              {item.content}
            </ToastController>
          </div>
        </animated.div>
      ))}
    </div>
  );

  return (
    <ToastContext.Provider
      value={{
        makeToast,
        removeToast,
      }}
    >
      {renderToasts()}
      {children}
    </ToastContext.Provider>
  );
};

export { Toaster };
