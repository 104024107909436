import * as colors from "@styles/emotion-styles/colors";

import type { ColorProfile } from "./colorProfile";

export const lightProfile: ColorProfile = {
  baseColor: colors.white,
  baseText: colors.dark,

  hoverColor: colors.background,
  hoverText: colors.dark,

  activeColor: colors.grey.lightFamily.light,
  outlineTextHover: colors.dark,
  outlineActiveColor: colors.white,

  lightNormalColor: colors.grey.lightFamily.normal,
  extraLightColor: colors.white,
};
