// Legacy Z Indexes
export const zIndexLowForegroundPlus = 101;
export const zIndexLowForeground = 99;
export const zIndexLowBackground = 98;
export const zIndexMidForeground = 999;
export const zIndexMidBackground = 998;
export const zIndexTopForeground = 9999;
export const zIndexTopBackground = 9998;

// Modal
export const indexModalMax = 8999;
export const indexModal = 8950;
export const indexModalMin = 8900;
export const modalDropdownMax = zIndexTopForeground + 1;

// Drawer
export const indexDrawerMax = 8899;
export const indexDrawer = 8850;
export const indexDrawerMin = 8800;

// Tooltips
export const indexTooltipMax = 8299;
export const indexTooltip = 8201;
export const indexTooltipMin = 8200;

// Dropdowns
export const indexDropdownMax = indexModalMax + 1;
export const indexDropdown = 8101;
export const indexDropdownMin = 8100;

export const indexDropdownContent = 5;

// NavBar (Navigation Bar, not sticky nav/page header)
export const indexNavBarMax = 7999;
export const indexNavBar = 7901;
export const indexNavBarMin = 7900;

// Nav Sidebar
export const indexNavSidebarMax = 7899;
export const indexNavSidebar = 7801;
export const indexNavSidebarMin = 7800;

// Overlay
export const indexOverlayMax = 7799;
export const indexOverlay = 7701;
export const indexOverlayMin = 7700;

// Alert Bar
export const indexAlertBarMax = 7699;
export const indexAlertBar = 7601;
export const indexAlertBarMin = 7600;

// Card
export const indexCardMax = 1999;
export const indexCard = 1100;
export const indexCardMin = 1000;

// Defaults
export const indexDefault = 1;
export const indexUnder = -1;

// A11y
export const indexAccessibleTop = 9999;
export const indexAccessibleBottom = 1;
export const indexAccessibleDefault = 0;
export const indexAccessibleUnder = -1;

// Debugging indexes
export const indexDebugTop = 9999;
export const indexDebugBottom = -9999;

export const indexRxStickyFooter = 101;
export const indexRxPatientList = 102;
