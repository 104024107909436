import { css } from "@emotion/react";

import { colors } from "@styles";

export const blockQuote = css`
  margin-left: 0.75rem;
  margin-bottom: 0.75rem;
  padding-left: 0.75rem;
  border-left: 2px solid ${colors.grey.lightFamily.normal};
`;
