import { css } from "@emotion/react";

import { easeOutCirc } from "./animations";
import { moderatelySlow } from "./timing";

export const clearfix = css`
  &:before,
  &:after {
    display: table;
    content: " ";
  }
  &:after {
    clear: both;
  }
`;

export const transition = css`
  transition: all ${moderatelySlow} ${easeOutCirc}, visibility 0s;
  transform-style: preserve-3d;
`;

export const noprint = css`
  @media print {
    display: none !important;
  }
`;

export const visuallyHidden = css`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
`;

export const visuallyHiddenReset = css`
  position: static;
  height: auto;
  width: auto;
  overflow: visible;
  clip: auto;
`;

export const transitionVerticalMaskIn = {
  visible: css`
    display: inline-block;
    overflow: hidden;
  `,

  hidden: css`
    border-top: 0;
    border-bottom: 0;
    height: 0px;
    padding: 0 1em;
    margin-bottom: -0.7em;
  `,
};
