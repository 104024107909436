import { css } from "@emotion/react";

import { colors } from "@styles";

export const clipBoard = css`
  position: absolute;
  top: 0.5rem;
  right: 0.875rem;
`;

export const iconButton = css`
  cursor: pointer;
`;

export const copyIcon = css`
  color: ${colors.blue.darkFamily.light};
`;

export const container = css`
  position: relative;
`;
