import getConfig from "next/config";
import dynamic from "next/dynamic";
import { FC } from "react";

const { publicRuntimeConfig } = getConfig();

const DynamicReactAxe = dynamic(
  () => publicRuntimeConfig?.REACT_AXE_ENABLED && import("./ReactAxe"),
  {
    ssr: false,
  }
);

const LazyReactAxe: FC = () => {
  if (!publicRuntimeConfig?.REACT_AXE_ENABLED) return null;
  return <DynamicReactAxe />;
};

export { LazyReactAxe };
