import { css } from "@emotion/react";

import { colors } from "@styles";

export const wrapper = css`
  display: inline-block;
  position: relative;
`;

export const tooltipOverrides = css`
  .MuiTooltip-popper strong {
    color: ${colors.white};
  }
`;

export const textDirection = {
  left: css`
    text-align: left;
  `,
  center: css`
    text-align: center;
  `,
  right: css`
    text-align: right;
  `,
};
