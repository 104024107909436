import { css } from "@emotion/react";

import { animations, baseStyles, colors, dimensions, helpers, timing, utilities } from "@styles";
import type { ColorProfile } from "@styles/emotion-styles/colorProfiles/colorProfile";
import { removeClickOutline } from "@styles/helpers";

export const button = {
  noStyle: css`
    appearance: none;
    border: none;
    background-color: transparent;
    text-align: left;
    font-size: inherit;
  `,
  base: css`
    ${baseStyles.controlBase}
    ${utilities.transition}
    justify-content: center;

    path {
      transition: color ${timing.moderatelySlow} ${animations.easeOutCirc};
    }

    cursor: pointer;
    color: ${colors.primary};
    border-color: ${colors.primary};
    font-weight: 600;
    white-space: nowrap;
    height: 2.75rem;

    padding-top: calc(0.375rem - ${dimensions.borderWidth});
    padding-bottom: calc(0.375rem - ${dimensions.borderWidth});
    padding-left: calc(1rem - ${dimensions.borderWidth});
    padding-right: calc(1rem - ${dimensions.borderWidth});

    &:hover {
      box-shadow: 0 1px 2px 0 ${helpers.hexToRgba(colors.dark, 0.25)};
    }

    ${removeClickOutline}

    ${helpers.loadingSpinner(colors.white)}

    &[disabled] {
      font-weight: 500;
      opacity: 0.9;
      user-select: none;
      box-shadow: none;
      pointer-events: none;
    }
  `,
  xsmall: css`
    height: 1.5rem;
    padding: 0 calc(0.5rem - ${dimensions.borderWidth});
  `,
  small: css`
    height: 2.25rem;
    padding-left: calc(0.75rem - ${dimensions.borderWidth});
    padding-right: calc(0.75rem - ${dimensions.borderWidth});
  `,
  large: css`
    height: 3.5rem;
    padding: calc(1rem - ${dimensions.borderWidth});
  `,
  fullwidth: css`
    width: 100%;
  `,
};

export const circular = css`
  border-radius: 50%;
`;

/*
 * Conditional Color application functions
 * -- Uses ColorProfiles
 */
export const normalStyles = (profile: ColorProfile) => css`
  color: ${profile.baseText};
  background-color: ${profile.baseColor};
  border-color: ${profile.baseBorder || profile.baseColor};

  &:hover {
    color: ${profile.hoverText};
    background-color: ${profile.hoverColor};
    border-color: ${profile.hoverBorder || profile.baseColor};
  }

  &:active,
  &:focus {
    background-color: ${profile.activeColor};
    border-color: ${profile.activeBorder || profile.activeColor};
  }

  &[disabled] {
    color: ${colors.grey.darkFamily.light};
    background-color: ${colors.grey.lightFamily.light};
    border-color: ${colors.grey.lightFamily.dark};

    path {
      color: ${colors.grey.darkFamily.light};
    }

    .fa-spinner-third path {
      fill: ${colors.grey.darkFamily.light};
    }

    &:hover,
    &:active {
      color: ${colors.grey.lightFamily.dark};
      background-color: ${colors.grey.lightFamily.light};
      border-color: ${colors.grey.lightFamily.dark};
    }
  }

  &:after {
    border-width: ${dimensions.borderWidth};
    border-style: solid;
    border-color: ${profile.baseText};
    border-right-color: transparent;
    border-top-color: transparent;
  }
`;

export const outlinesStyles = (profile: ColorProfile) => css`
  color: ${profile.outlineText || profile.baseColor};
  background-color: ${profile.outlineBackground || profile.baseText};
  border-color: ${profile.outlineBorderColor || profile.baseColor};

  &:hover {
    .svg-inline--fa path:not(.fa-spinner-third) {
      fill: ${profile.baseColor};
    }
  }

  &:focus,
  &:active {
    .svg-inline--fa path:not(.fa-spinner-third) {
      fill: ${profile.baseText};
    }
  }

  &:hover {
    color: ${profile.outlineTextHover || profile.outlineText || profile.baseColor};
    background-color: ${colors.white};
    border-color: ${profile.outlineBorderColor || profile.baseColor};
  }

  &:active,
  &:focus {
    color: ${profile.outlineTextHover || colors.white};
    background-color: ${profile.outlineActiveColor};
    border-color: ${profile.outlineActiveBorderColor};
  }

  &:after {
    border: 2px solid ${profile.baseColor};
    border-right-color: transparent;
    border-top-color: transparent;
  }
`;

export const textTypeStyle = (profile: ColorProfile) => css`
  color: ${profile.textBaseColor || profile.baseColor};
  background-color: transparent;
  border-color: transparent;

  &[disabled] {
    &,
    &:hover,
    &:focus {
      background: none;
      border-color: transparent;
      opacity: 0.5;
    }
  }

  &:hover {
    box-shadow: none;
    color: ${profile.textHover || profile.outlineTextHover || profile.hoverColor};
    background-color: ${colors.white};
    border-color: ${colors.white};
  }

  &:active,
  &:focus {
    color: ${profile.textActive || profile.outlineTextHover || profile.activeColor};
    background-color: ${colors.grey.lightFamily.light};
    border-color: ${colors.grey.lightFamily.light};
    box-shadow: 0 0 0 0.05rem ${helpers.hexToRgba(profile.activeColor, 0.15)};
  }
`;

export const loadingStyles = css`
  color: transparent !important;
  pointer-events: none;
  user-select: none;

  path:not(.fa-spinner-third) {
    color: transparent !important;
  }

  &[disabled] {
    path:not(.fa-spinner-third) {
      color: transparent !important;
    }
  }
`;

/*
 * Loader Icon Styles
 */
export const loader = css`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const loaderIcon = css`
  animation-duration: ${timing.slow};
  display: block;
`;

export const normalSVG = (profile: ColorProfile) => css`
  path {
    fill: ${profile.baseText};
  }
`;

export const textTypeSVG = (profile: ColorProfile) => css`
  path {
    fill: ${profile.textBaseColor || profile.baseColor};
  }
`;

export const outlineSVG = (profile: ColorProfile) => css`
  path {
    fill: ${profile.outlineText || profile.baseColor};
  }
`;

export const baseIconStyles = css`
  width: 2.75rem;
  height: 2.75rem;
  padding: 0 !important;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const iconStyles = {
  xsmall: css`
    height: 1.5rem;
    width: 1.5rem;
  `,
  small: css`
    height: 2.25rem;
    width: 2.25rem;
  `,
  large: css`
    height: 3rem;
    width: 3rem;
  `,
};

/*
 * Latch Styling, utilized with button groups
 */
const notLeftLatch = color => css`
  border-right: none;
  &:after {
    position: absolute;
    right: 0;
    content: "";
    background-color: ${color};
    height: 1.5rem;
    width: 1px;
    margin-right: -1px;
  }
`;
const notRightLatch = color => css`
  border-left: none;
  &:before {
    position: absolute;
    left: 0;
    content: "";
    background-color: ${color};
    height: 100%;
    width: 1px;
    margin-left: -1px;
  }
`;

const notRightLatchDisabled = css`
  &:before {
    background-color: ${colors.splitButtonDisabledSeparator};
    width: 1px;
  }
`;

export const latch = (profile: ColorProfile) => {
  return {
    left: css`
      ${notRightLatch(profile.splitSeparator)};
      border-radius: 0 ${dimensions.borderRadius} ${dimensions.borderRadius} 0;
    `,
    middle: css`
      ${notLeftLatch(profile.splitSeparator)}
      ${notRightLatch(profile.splitSeparator)}
    border-radius: 0;
    `,
    right: css`
      ${notLeftLatch(profile.splitSeparator)};
      border-radius: ${dimensions.borderRadius} 0 0 ${dimensions.borderRadius};
    `,
    disabled: css`
      ${notRightLatchDisabled}
    `,
  };
};
