import type { ButtonColors } from "@aviary/types/aviaryColors";
import { profiles } from "@styles";

export const stringToProfile = {
  primary: profiles.primaryProfile,
  dark: profiles.darkProfile,
  danger: profiles.dangerProfile,
  warning: profiles.warningProfile,
  info: profiles.infoProfile,
  light: profiles.lightProfile,
  purple: profiles.purpleProfile,
  system: profiles.systemProfile,
};

export const defaultButtonColor: ButtonColors = "primary";

export const defaultButtonProps = {
  isColor: defaultButtonColor,
  isSkeleton: false,
};
