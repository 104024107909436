import { css } from "@emotion/react";

import { animations, colors, dimensions, helpers, layers, timing } from "@styles";

export const base = css`
  ${animations.transition()};
  position: relative;
  border-radius: ${dimensions.borderRadius} ${dimensions.borderRadius};
  z-index: ${layers.indexModalMax};
  box-shadow: 0 2px 4px 0 ${helpers.hexToRgba(colors.dark, 0.2)};
  min-width: 10rem;
  max-width: 24.5rem;
  display: flex;
  align-content: stretch;
`;

export const content = css`
  padding: 1.25rem 2.5rem 1.25rem 1.25rem;
  flex: 1;
  color: ${colors.white};
  background-color: ${colors.dark};
  border-radius: 0 ${dimensions.borderRadius} ${dimensions.borderRadius} 0;
`;

export const timeDown = (initialTime: number, remaining: number) => css`
  background-color: ${colors.dark};
  flex: 1;
  height: ${(remaining / initialTime) * 100}%;
  transition: height ${timing.ultraFast} linear;
  width: 100%;
  margin-top: auto;
  margin-bottom: 0;
  border-radius: ${dimensions.borderRadius} 0 0 ${dimensions.borderRadius};
`;

export const timer = css`
  width: 0.25rem;
  position: relative;
  display: flex;
  border-radius: ${dimensions.borderRadius} 0 0 ${dimensions.borderRadius};
  background-color: ${colors.green.lightFamily.light};
`;

export const timerTypes = {
  normal: css`
    background-color: ${colors.grey.lightFamily.dark};
  `,
  error: css`
    background-color: ${colors.red.lightFamily.normal};
  `,
  success: css`
    background-color: ${colors.green.lightFamily.normal};
  `,
};

export const progressTypes = {
  normal: css`
    background-color: ${helpers.hexToRgba(colors.dark, 0.5)};
  `,
  error: css`
    background-color: ${colors.red.lightFamily.dark};
  `,
  success: css`
    background-color: ${helpers.hexToRgba(colors.green.base, 0.5)};
  `,
};

export const types = {
  normal: css`
    background-color: ${colors.dark};
  `,
  error: css`
    background-color: ${colors.danger};
  `,
  success: css`
    background-color: ${colors.success};
  `,
};

// TODO: Replace this styling with the new `isColor="white"` aviary color version
export const close = css`
  path {
    fill: ${colors.white};
  }

  &:hover path,
  &:active path {
    fill: ${colors.grey.base};
  }
`;
