import type { FC } from "react";

import * as styles from "./Skeleton.styles";

interface Props {
  /**
   * Specifies the type of element you are skeletonizing
   *
   */
  type?: "button" | "typography" | "image";
  /**
   * Specifies the height of the element if type "heading" is selected
   *
   * @default h2
   */
  typographySize?: "h1" | "h2" | "h3" | "h4" | "h5" | "body" | "footnote" | "caption";
  /**
   * Specifies the size element if type "button" is selected
   *
   * @default medium
   */
  buttonSize?: "xsmall" | "small" | "medium" | "large";
  /**
   * Specifies the size element if type "image" is selected
   *
   * @default small
   */
  imageSize?: "tiny" | "small" | "medium" | "large" | "extraLarge" | "fill";
  /**
   * Define a custom width, specified in rem's. Overrides `isFullwidth` and default widths defined by the types
   *
   */
  width?: number;
  /**
   * Define a custom height, specified in rem's
   *
   */
  height?: number;
  /**
   * Specifies if the element should be full (100%) width
   *
   */
  isFullwidth?: boolean;
  /**
   * Specifies if the element should be fully rounded
   *
   * @default false
   */
  isCircular?: boolean;
  /**
   * Allows "pulling" or floating the element to the left or right
   *
   */
  floated?: "left" | "right";
  /**
   * Allows custom margin in REM's to be added to the bottom of the element
   *
   */
  margin?:
    | "bottom"
    | "bottomLarge"
    | "bottomButton"
    | "top"
    | "topLarge"
    | "topButton"
    | "topAndBottom"
    | "topAndBottomLarge";
}

const Skeleton: FC<Props> = ({
  type,
  buttonSize,
  width,
  height,
  isFullwidth,
  isCircular,
  imageSize,
  typographySize,
  floated,
  margin,
  ...rest
}) => {
  const commonStylesBuilder = () => {
    return [
      isFullwidth && styles.fullWidth,
      isCircular && styles.circular,
      width && styles.customWidth(width),
      height && styles.customHeight(height),
      floated && styles.floated[floated],
      margin && styles.margin[margin],
    ];
  };

  const skeletonBuilder = () => {
    if (type === "button") {
      return [styles.button.base, styles.button[buttonSize], ...commonStylesBuilder()];
    }
    if (type === "typography") {
      return [styles.heading.base, styles.heading[typographySize], ...commonStylesBuilder()];
    }
    if (type === "image") {
      return [styles.image.base, styles.image[imageSize], ...commonStylesBuilder()];
    }
    return [styles.baseSkeleton, ...commonStylesBuilder()];
  };

  return <div data-testid="aviary-skeleton" css={skeletonBuilder()} {...rest} />;
};

Skeleton.defaultProps = {
  typographySize: "h2",
  buttonSize: "medium",
  imageSize: "small",
};

export { Skeleton };
