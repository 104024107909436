import type { FC, HTMLProps, ReactNode } from "react";

import { Skeleton } from "../Skeleton";

import * as styles from "./Typography.styles";

export type TypographyType = "h1" | "h2" | "h3" | "h4" | "h5" | "body" | "footnote" | "caption";

interface Props extends HTMLProps<HTMLElement> {
  type?: TypographyType;
  sizeOverride?: "h1" | "h2" | "h3" | "h4" | "h5" | "body" | "footnote" | "caption";
  isIndented?: boolean;
  isSecondaryWeight?: boolean;
  isFullWidth?: boolean;
  isMarginless?: boolean;
  isMuted?: boolean;
  isSkeleton?: boolean;
  children?: ReactNode;
  "data-testid"?: string;
}

const Typography: FC<Props> = ({
  type,
  sizeOverride,
  isIndented,
  isSecondaryWeight,
  isFullWidth,
  isMarginless,
  isMuted,
  isSkeleton,
  children,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref,
  ...rest
}: Props) => {
  const titleStyleBuilder = () => {
    return [
      styles.titleBase,
      styles.titleSizes[type](isSecondaryWeight),
      isMuted && styles.isMuted,
      isIndented && styles.indented,
      isFullWidth && styles.fullwidth,
      !isMarginless && styles.margin,
      sizeOverride && styles.sizeOverride[sizeOverride](isSecondaryWeight),
    ];
  };

  const paragraphStyleBuilder = () => {
    return [
      styles.paragraphBase,
      styles.paragraphSizes[type](isSecondaryWeight),
      isMuted && styles.isMuted,
      isFullWidth && styles.fullwidth,
      !isMarginless && styles.margin,
      sizeOverride && styles.sizeOverride[sizeOverride](isSecondaryWeight),
    ];
  };

  const renderType = () => {
    switch (type) {
      case "h1":
        return (
          <h1 css={titleStyleBuilder()} {...rest}>
            {children}
          </h1>
        );
      case "h2":
        return (
          <h2 css={titleStyleBuilder()} {...rest}>
            {children}
          </h2>
        );
      case "h3":
        return (
          <h3 css={titleStyleBuilder()} {...rest}>
            {children}
          </h3>
        );
      case "h4":
        return (
          <h4 css={titleStyleBuilder()} {...rest}>
            {children}
          </h4>
        );
      case "h5":
        return (
          <h5 css={titleStyleBuilder()} {...rest}>
            {children}
          </h5>
        );
      default:
        return (
          <p css={paragraphStyleBuilder()} {...rest}>
            {children}
          </p>
        );
    }
  };

  const getSize = () => {
    if (sizeOverride) {
      return sizeOverride;
    }
    return type ? type : "body";
  };

  const getElement = () => {
    if (isSkeleton) {
      return <Skeleton type="typography" typographySize={getSize()} isFullwidth={isFullWidth} />;
    }
    return renderType();
  };

  return getElement();
};

Typography.defaultProps = {
  type: "body",
  isSecondaryWeight: false,
  isMuted: false,
};

export { Typography };
