/* eslint-disable @typescript-eslint/naming-convention */
import { config, library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faCog, faSignOutAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { MDXProvider } from "@mdx-js/react";
import App from "next/app";

import { Toaster } from "@aviary";

import { LazyReactAxe } from "../src/components";
import { MDXComponents } from "../src/components/docs";

import "../src/styles/bulma.scss";
import "../src/styles/mixins.scss";
import "../src/styles/variables.scss";
import "../src/styles/algolia_overrides.scss";
import "../src/styles/remark_admonition_styles.scss";

config.autoAddCss = false;
library.add(fab, faSignOutAlt, faUser, faCog);

class MyApp extends App {
  render() {
    const { Component, pageProps } = this.props;

    return (
      <>
        <Toaster>
          <MDXProvider components={MDXComponents}>
            <Component {...pageProps} />
          </MDXProvider>
        </Toaster>
        <LazyReactAxe />
      </>
    );
  }
}

export default MyApp;
