import { faExternalLink } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, ReactNode, useEffect, useState } from "react";

import * as styles from "./Link.styles";

interface Props {
  children: ReactNode;
  href: string;
}

const Link: FC<Props> = props => {
  const [isExternalLink, setIsExternalLink] = useState(false);

  useEffect(() => {
    try {
      const href = new URL(props.href);
      setIsExternalLink(href.hostname !== window.location.hostname);
    } catch (error) {
      // Do nothing
    }
  }, []);

  const target = isExternalLink ? "_blank" : "_self";

  return (
    <span>
      <a {...props} css={styles.link} target={target} rel="noreferrer noopener" />
      {isExternalLink && <FontAwesomeIcon size="xs" icon={faExternalLink} css={styles.icon} />}
    </span>
  );
};

export { Link };
