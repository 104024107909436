import * as colors from "@styles/emotion-styles/colors";

import type { ColorProfile } from "./colorProfile";

export const infoProfile: ColorProfile = {
  baseColor: colors.blue.base,
  baseText: colors.white,

  hoverColor: colors.blue.darkFamily.light,
  hoverText: colors.white,

  activeColor: colors.blue.darkFamily.darker,
  outlineActiveColor: colors.blue.darkFamily.light,

  lightNormalColor: colors.blue.lightFamily.normal,
  extraLightColor: colors.blue.lightFamily.light,
};
