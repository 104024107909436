import { css, keyframes } from "@emotion/react";

import * as colors from "./colors";
import { hexToRgba } from "./helpers/hexToRgba";
import * as timing from "./timing";

export const easeInQuad = "cubic-bezier(0.55, 0.085, 0.68, 0.53)";
export const easeInCubic = "cubic-bezier(0.55, 0.055, 0.675, 0.19)";
export const easeInQuart = "cubic-bezier(0.895, 0.03, 0.685, 0.22)";
export const easeInQuint = "cubic-bezier(0.755, 0.05, 0.855, 0.06)";
export const easeInExpo = "cubic-bezier(0.95, 0.05, 0.795, 0.035)";
export const easeInCirc = "cubic-bezier(0.6, 0.04, 0.98, 0.335)";

export const easeOutQuad = "cubic-bezier(0.25, 0.46, 0.45, 0.94)";
export const easeOutCubic = "cubic-bezier(0.215, 0.61, 0.355, 1)";
export const easeOutQuart = "cubic-bezier(0.165, 0.84, 0.44, 1)";
export const easeOutQuint = "cubic-bezier(0.23, 1, 0.32, 1)";
export const easeOutExpo = "cubic-bezier(0.19, 1, 0.22, 1)";
export const easeOutCirc = "cubic-bezier(0.075, 0.82, 0.165, 1)";

export const easeInOutQuad = "cubic-bezier(0.455, 0.03, 0.515, 0.955)";
export const easeInOutCubic = "cubic-bezier(0.645, 0.045, 0.355, 1)";
export const easeInOutQuart = "cubic-bezier(0.77, 0, 0.175, 1)";
export const easeInOutQuint = "cubic-bezier(0.86, 0, 0.07, 1)";
export const easeInOutExpo = "cubic-bezier(1, 0, 0, 1)";
export const easeInOutCirc = "cubic-bezier(0.785, 0.135, 0.15, 0.86)";

// Helpers

export const transition = (
  property = "all",
  duration = timing.fast,
  easing = "cubic-bezier(0.215, 0.61, 0.355, 1)"
) => css`
  transition: ${property} ${duration} ${easing};
  transform-style: preserve-3d;
`;

export const animation = (
  name,
  duration = timing.fast,
  easing = "cubic-bezier(0.215, 0.61, 0.355, 1)"
) => css`
  animation: ${name} ${duration} ${easing};
`;

// Keyframes

export const pulseBox = keyframes`
  0% {
    box-shadow: 0 0 0 0 ${hexToRgba(colors.primary, 0.5)};
  }
  70% {
    box-shadow: 0 0 0 10px transparent;
  }
  100% {
    box-shadow: 0 0 0 0 transparent;
  }
`;

export const pulseError = keyframes`
  0% {
    -moz-box-shadow: 0 0 0 0 ${hexToRgba(colors.danger, 0.5)};
    box-shadow: 0 0 0 0 ${hexToRgba(colors.danger, 0.5)};
  }
  70% {
    -moz-box-shadow: 0 0 0 6px ${hexToRgba(colors.danger, 0.4)};
    box-shadow: 0 0 0 6px ${hexToRgba(colors.danger, 0.4)};
  }
  100% {
    -moz-box-shadow: 0 0 0 0 ${hexToRgba(colors.danger, 0.1)};
    box-shadow: 0 0 0 0 ${hexToRgba(colors.danger, 0.1)};
  }
`;

export const slideFromLeft = keyframes`
  0% {
    left: -100%;
  }

  100% {
    left: 0%;
  }
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const fadeInOverlay = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 0.6;
  }
`;

export const slideFadeInTop = keyframes`
  from {
    transform: translateY(-10%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const slideFadeOutTop = keyframes`
from {
  transform: translateY(0);
  opacity: 1;
}
  to {
    transform: translateY(-10%);
    opacity: 0;
  }
`;

export const slideFadeInFromLeft = keyframes`
  from {
    transform: translateX(-20%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const slideFadeOutToRight = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(20%);
    opacity: 0;
  }
`;

export const slideFadeOutToLeft = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-20%);
    opacity: 0;
  }
`;

export const wiggleIn = keyframes`
  0% {
    transform: translateX(-5px);
    opacity: 0;
  }

  25% {
    transform: translateX(5px);
    opacity: 1;
  }

  50% {
    transform: translateX(-2.5px);
    opacity: 1;
  }

  75% {
    transform: translateX(2.5px);
    opacity: 1;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const pulseComplete = keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.025);
  }

  100% {
    transform: scale(1);
  }
`;

export const fadeInAndGrowUp = (growToMinHeight = "0", growToMaxHeight = "10rem") => keyframes`
  from {
    opacity: 0;
    min-height: 0;
    max-height: 0;
  }

  to {
    opacity: 1;
    min-height: ${growToMinHeight};
    max-height: ${growToMaxHeight};
  }
`;

export const fadeInAndGrowOut = (growToMinWidth = "0", growToMaxWidth = "10rem") => keyframes`
  from {
    opacity: 0;
    min-width: 0;
    max-width: 0;
  }

  to {
    opacity: 1;
    min-width: ${growToMinWidth};
    max-width: ${growToMaxWidth};
  }
`;

export const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

// Utilities

export const hoverBoxShadow = css`
  box-shadow: 0 2px 12px ${hexToRgba(colors.dark, 0.25)};
  transition: all ${timing.fast} ease-in-out;
`;

// Skeletons

const skeletonPulseKeyframes = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
`;

export const skeletonPulse = css`
  height: 100%;
  background: linear-gradient(
    -90deg,
    ${colors.grey.lightFamily.dark} 0%,
    ${colors.grey.lightFamily.normal} 50%,
    ${colors.grey.lightFamily.dark} 100%
  );
  width: 100%;
  background-size: 400% 400%;
  animation: ${skeletonPulseKeyframes} ${timing.ultraSlow} ${easeInOutCubic} infinite;
`;
