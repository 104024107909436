import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { FC, HTMLProps, ReactNode, Ref } from "react";
import { useTranslation } from "react-i18next";
import type { LinkProps } from "react-router-dom";
import { Link } from "react-router-dom";

import { l } from "@aviary/locales/i18n";
import type { AviaryButtonColorProps } from "@aviary/types/aviaryColors";

import { Skeleton } from "../Skeleton";

import { stringToProfile, defaultButtonProps } from "./buttonHelpers";

import * as styles from "./Button.styles";

type BasicLinkProps = Partial<Pick<LinkProps, "to" | "component" | "replace" | "innerRef">>;

interface ButtonProps<T = HTMLButtonElement>
  extends AviaryButtonColorProps,
    BasicLinkProps,
    HTMLProps<T> {
  isLoading?: boolean;
  isOutlined?: boolean;
  isText?: boolean;
  innerRef?: Ref<any>;
  isCircular?: boolean;
  isFullWidth?: boolean;
  isIcon?: boolean;
  isSkeleton?: boolean;
  isSize?: "xsmall" | "small" | "large";
  latch?: "left" | "right" | "middle";
  isLoaderDisabled?: boolean;
  children?: ReactNode;
  /**
   * DEPRECATED, use <NoStyleButton /> instead
   */
  noStyle?: boolean;
  "data-testid"?: string;
}

export type ButtonOrLinkType = ButtonProps<HTMLButtonElement | HTMLAnchorElement>;

const Button: FC<ButtonOrLinkType> = ({
  children,
  component,
  disabled,
  href,
  innerRef,
  isCircular,
  isColor,
  isFullWidth,
  isIcon,
  isLoading,
  isOutlined,
  isSize,
  isSkeleton,
  isText,
  latch,
  noStyle,
  replace,
  to,
  type,
  isLoaderDisabled,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  "data-testid": dataTestId,
  ...rest
}: ButtonOrLinkType) => {
  const { t } = useTranslation("aviary");

  const buttonBuilder = () => {
    if (noStyle) return styles.button.noStyle;
    return [
      isLoading && styles.loadingStyles,
      styles.button.base,
      styles.normalStyles(stringToProfile[isColor]),
      styles.latch(stringToProfile[isColor])[latch],
      disabled && styles.latch(stringToProfile[isColor]).disabled,
      isFullWidth && styles.button.fullwidth,
      isCircular && styles.circular,
      isText && styles.textTypeStyle(stringToProfile[isColor]),
      isOutlined && styles.outlinesStyles(stringToProfile[isColor]),
      styles.button[isSize],
      isIcon && iconBuilder(),
    ];
  };

  const iconBuilder = () => {
    return (
      !noStyle && [
        isIcon && styles.baseIconStyles,
        isIcon && styles.iconStyles[isSize],
        styles.normalSVG(stringToProfile[isColor]),
        isOutlined && styles.outlineSVG(stringToProfile[isColor]),
        isText && styles.textTypeSVG(stringToProfile[isColor]),
      ]
    );
  };

  const loaderBuilder = () => {
    return (
      !noStyle && [
        styles.loaderIcon,
        styles.normalSVG(stringToProfile[isColor]),
        isOutlined && styles.outlineSVG(stringToProfile[isColor]),
        isText && styles.textTypeSVG(stringToProfile[isColor]),
      ]
    );
  };

  const renderLoader = () => {
    if (!noStyle && !isLoaderDisabled && isLoading) {
      return (
        <div css={styles.loader}>
          <FontAwesomeIcon
            css={loaderBuilder()}
            icon={faSpinnerThird}
            spin
            title={t(l.aviary.button.Loading)}
          />
        </div>
      );
    }
  };

  const getValidButtonType = () => {
    if (type === "submit" || type === "reset") {
      return type;
    } else {
      return "button";
    }
  };

  const button = () => (
    <button
      css={buttonBuilder()}
      disabled={disabled}
      {...(rest as HTMLProps<HTMLButtonElement>)}
      ref={innerRef}
      type={getValidButtonType()}
    >
      {renderLoader()}
      {children}
    </button>
  );

  const anchor = () => (
    <a
      css={buttonBuilder()}
      role="button"
      href={href}
      {...(rest as HTMLProps<HTMLAnchorElement>)}
      ref={innerRef}
    >
      {renderLoader()}
      {children}
    </a>
  );

  const routerLink = () => (
    <Link
      css={buttonBuilder()}
      to={to}
      component={component}
      replace={replace}
      data-testid="Link"
      {...rest}
      ref={innerRef}
    >
      {renderLoader()}
      {children}
    </Link>
  );

  const getElement = () => {
    if (isSkeleton) {
      return <Skeleton type="button" buttonSize={isSize} isFullwidth={isFullWidth} />;
    } else if (!disabled) {
      if (to) {
        return routerLink();
      } else if (href) {
        return anchor();
      }
    }
    return button();
  };

  return getElement();
};

Button.defaultProps = defaultButtonProps;

export type { ButtonProps };
export { Button };
