import { css } from "@emotion/react";

import { colors } from "@styles";

export const link = css`
  color: ${colors.blue.darkFamily.normal};
  text-decoration: underline;

  &:hover {
    color: ${colors.blue.darkFamily.darker};
  }
`;

export const icon = css`
  color: ${colors.blue.darkFamily.light};
  margin-left: 0.25rem;
`;
