import type { FC } from "react";

import type { ButtonProps } from "@aviary/components/Button/Button";
import { Button } from "@aviary/components/Button/Button";

interface IconButtonProps extends ButtonProps {
  "aria-label": string;
}

const IconButton: FC<IconButtonProps> = ({ children, ...rest }) => {
  return (
    <Button isIcon {...rest}>
      {children}
    </Button>
  );
};

export type { IconButtonProps };
export { IconButton };
