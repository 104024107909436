import { FC, useState, useEffect } from "react";

import { ToastProps, Toast } from "../Toast";

const ToastController: FC<ToastProps> = props => {
  const { autoDismiss, autoDismissTimeout, onDismiss, children } = props;

  const [isRunning, setIsRunning] = useState(Boolean(autoDismiss));
  const [remaining, setRemaining] = useState(autoDismissTimeout);

  useEffect(() => {
    let interval = null;
    if (remaining === 0) onDismiss();
    if (isRunning && remaining > 0) {
      interval = setInterval(() => {
        setRemaining(seconds => seconds - 100);
      }, 100);
    } else if (!isRunning && remaining !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isRunning, remaining]);

  const onMouseEnter = () => {
    setIsRunning(false);
  };
  const onMouseLeave = () => {
    setIsRunning(true);
  };

  const handleMouseEnter = autoDismiss
    ? onMouseEnter
    : () => {
        return null;
      };
  const handleMouseLeave = autoDismiss
    ? onMouseLeave
    : () => {
        return null;
      };

  return (
    <Toast
      autoDismiss={autoDismiss}
      autoDismissTimeout={autoDismissTimeout}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      timeRemaining={remaining}
      {...props}
    >
      <span data-e2e="toast-content">{children}</span>
    </Toast>
  );
};

ToastController.defaultProps = {
  autoDismissTimeout: 5000,
  autoDismiss: true,
};

export { ToastController };
