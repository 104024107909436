import * as colors from "@styles/emotion-styles/colors";

import type { ColorProfile } from "./colorProfile";

export const systemProfile: ColorProfile = {
  baseColor: colors.white,
  baseText: colors.black,
  baseBorder: colors.grey.lightFamily.dark,

  outlineText: colors.black,
  outlineTextHover: colors.black,
  outlineBackground: colors.white,
  outlineBorderColor: colors.grey.lightFamily.dark,
  outlineActiveColor: colors.white,

  textBaseColor: colors.black,
  textHover: colors.grey.darkFamily.light,
  textActive: colors.grey.darkFamily.darker,

  hoverColor: colors.white,
  hoverBorder: colors.grey.lightFamily.darker,
  hoverText: colors.black,

  activeColor: colors.white,
  activeBorder: colors.blue.base,
};
